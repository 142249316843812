import '../css/nav.css';
import '../css/App.css';
import logo from '../img/hg_logo_bw.png';
import toggle from '../img/menu_white.svg';
import { useState, useEffect } from 'react';
import Scrollspy from 'react-scrollspy-highlight';
import { Link } from 'react-scroll';

export default function Navbar() {
	// for menu button
	const [toggleOn, setToggleOn] = useState(false);

	const handleToggle = () => {
		setToggleOn(!toggleOn);
	};

	// for shadow on scroll
	const [scroll, setScroll] = useState(false);
	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50);
			setToggleOn(false);
		});
	}, []);

	return (
		<nav
			className={`navbar navbar-expand-md fixed-top scrolling-navbar bg-white ${
				scroll ? 'menu-bg' : null
			}`}
		>
			<div className="container">
				<Link className="navbar-brand" to="home">
					<img src={logo} />
				</Link>
				<div className="collapse navbar-collapse" id="navbarCollapse">
					<Scrollspy
						offset={-10}
						className="navbar-nav mr-auto w-100 justify-content-center"
						items={['home', 'whoweare', 'whatwedo', 'works', 'team', 'contact']}
						currentClassName="current"
					>
						<Link className="nav-link page-scroll" to="home" smooth={true} duration={500}>
							HOME
						</Link>

						<Link className="nav-link page-scroll" to="whoweare" smooth={true} duration={500}>
							WHO WE ARE
						</Link>

						<Link className="nav-link page-scroll" to="whatwedo" smooth={true} duration={500}>
							WHAT WE DO
						</Link>

						<Link
							className="nav-link page-scroll"
							to="works"
							offset={-60}
							smooth={true}
							duration={500}
						>
							WORKS
						</Link>

						<Link className="nav-link page-scroll" to="team" smooth={true} duration={500}>
							MEET OUR TEAM
						</Link>

						<Link className="nav-link page-scroll" to="contact" smooth={true} duration={500}>
							CONTACT
						</Link>
					</Scrollspy>
				</div>
				<button
					className="navbarToggle"
					type="button"
					data-toggle="collapse"
					data-target="#navbarCollapse"
					aria-controls="navbarCollapse"
					aria-expanded="false"
					aria-label="Toggle navigation"
					onClick={handleToggle}
				>
					<img src={toggle} alt="toggle" />
				</button>
			</div>

			{/* DROPDOWN */}
			<div className={`dropdown ${toggleOn ? 'dropdownOn' : null}`}>
				<Scrollspy
					offset={-15}
					className="navbar-nav dropdown-nav mr-auto w-100 flex-column"
					items={['home', 'whoweare', 'whatwedo', 'works', 'team', 'contact']}
					currentClassName="dropdown-current"
				>
					<Link
						className="nav-link page-scroll"
						to="home"
						smooth={true}
						duration={500}
						onClick={() => {
							setToggleOn(false);
						}}
					>
						HOME
					</Link>
					<Link
						className="nav-link page-scroll"
						to="whoweare"
						spy={true}
						smooth={true}
						duration={500}
					>
						WHO WE ARE
					</Link>
					<Link
						className="nav-link page-scroll"
						to="whatwedo"
						spy={true}
						smooth={true}
						duration={500}
					>
						WHAT WE DO
					</Link>
					<Link className="nav-link page-scroll" to="works" spy={true} smooth={true} duration={500}>
						WORKS
					</Link>
					<Link className="nav-link page-scroll" to="team" spy={true} smooth={true} duration={500}>
						MEET OUR TEAM
					</Link>
					<Link
						className="nav-link page-scroll"
						to="contact"
						spy={true}
						smooth={true}
						duration={500}
					>
						CONTACT
					</Link>
				</Scrollspy>
			</div>
		</nav>
	);
}
